/*
  +-------------------------------------------------------------+
  !     CODE SOURCE MATERIALS                                   !
  !     DIGITECH CONFIDENTIAL                                   !
  !                                                             !
  !     DIGITECH Platform Code                                  !
  !     (C) COPYRIGHT DIGITECH 2022-2024                        !
  !     Licensed Internal Code - Property of DIGITECH           !
  +-------------------------------------------------------------+
  +-------------------------------------------------------------+
  !                                                             !
  !  File  : frontconfig.js                                     !
  !  Desc. : Nodejs Digines Front configuration parameters      !
  !                                                             !
  !  Author: D.ESTEVE                                           !
  !  Modif.: 10/04/2024                                         !
  !                                                             !
  !  0.1: Creation                                              !
  !  1.0: Adaptation to new platform                            !
  !  1.1: Admin reset password and Log Files download           !
  !  1.2: Add creation record in ticket detail                  !
  !  1.3: On reopen set state 'Requesting Digitech response'    !
  !  2.1: New display for Ticket details                        !
  !  2.2.0: Severity text and Product in Tickets list           !
  !  2.3.0: Fix URL for Digitech support                        !
  +-------------------------------------------------------------+
*/
/*=============== Imports ======================================*/
/*
--- Digitech products
*/
import {backinterfaceBackServerPort} from "./diginesback/backinterface.js";

/*=============== Definitions ==================================*/
/*
--- Diginesfront configuration parameters
*/
export const frontconfig = {
    version: "2.3.0",
    modification: "07/10/2024",
    locale: "fr-FR",
    backServerHostname: "client-backend.digitech.fr",
    // backServerHostname: "localhost",
    backServerPort: backinterfaceBackServerPort,
    colors: {
        backgroundMainGrey: "#6F7170",
        backgroundMainBlack: "#000000",
        backgroundHeader: "#ffffff",
        backgroundIcon: "#6F7170",
        backgroundTableHead: "#6F7170",
        backgroundTableDetail : "#e5e5e5",
        backgroundHighlight : "#f6f203",
        foregroundMain: "#ffffff",
        foregroundHeader: "#6F7170",
        foregroundIcon: "#ffffff",
        foregroundError: "#ff0000",
        foregroundIconButton: "#1976d2",
        foregroundTableHead: "#ffffff",
        hoverTableHead: "#8d8d8d",
        borderTable: "#e0e0e0"

    },
    supportResponsibleId: "68",
    maxFileSize: 31457280,
    maxFaqDocFileSize: 524288000,
    urlFilesDownload: "https://client-backend.digitech.fr",
    urlOld1FilesDownload: "https://client.digitech.fr:3746",
    urlOld2FilesDownload: "https://client.digitech.fr:80",
    urlInesFiles: "https://extend.inescrm.com/maxdownload/download.dll/download?QN43oNHomA1InA16lBmNVP4ZVQnp2GIR7J2J1G+++&fic_ref=",
    urlDigitechGroup: "https://digitech-group.net",
    urlDigitechFrance: "https://www.digitech.fr",
    urlDigitechSwiss: "https://www.digitech.swiss",
    urlTwitterFrance: "https://twitter.com/Digitech_France",
    urlTwitterSwiss: "https://twitter.com/group_digitech",
    urlLinkedinFrance: "https://www.linkedin.com/company/digitech-france",
    urlLinkedinSwiss: "https://www.linkedin.com/company/digitech-sa",
    urlYoutubeFrance: "https://www.youtube.com/channel/UCrkBNaNJBfxIkNZOz1c9gxg",
    urlYoutubeSwiss: "https://www.youtube.com/channel/UCl95T5O_RI39ROgm8WvIbXg",
    urlMapsFrance: "https://goo.gl/maps/2tXgycMJkj6z1feA6",
    urlMapsSwiss: "https://goo.gl/maps/vkAUNUtoskp7nrgXA",
    addressNameFrance : "ZAC Saumaty Séon - CS 40173",
    addressWayFrance : "21, avenue Fernand SARDOU",
    addressCityFrance : "13322 MARSEILLE cedex 16",
    addressNameSwiss : "Digitech SA",
    addressWaySwiss : "Avenue du Premier-Mars 4",
    addressCitySwiss : "2000 Neuchâtel",
    clientTypeSwiss : "23",
    logCurrentFilename : "diginesback.log",
    logBackupFilename : "diginesback.bkp",
    magicTags : {
        author: "$$$Author: ",
        product: "$$$Product: "
    }
};